<template>
  <div id="app">
    <el-container>
      <el-header>
        <div class="logo">
          <img src="@/assets/img/yqmatch.png"/>
          <div>
            <div class="t1">上海三竿水科技有限公司</div>
            <div class="t2">Shang Hai San Gan Shui Technology Corporation</div>
          </div>
        </div>
        <el-menu :default-active="defaultActive" class="el-menu-demo" mode="horizontal" @select="handleSelect" :router="router">
          <el-menu-item index="/">首页</el-menu-item>
          <el-menu-item index="/news">新闻资讯</el-menu-item>
          <el-menu-item index="mall">盈球商城</el-menu-item>
          <el-menu-item index="/stadiums">场地预定(付费)</el-menu-item>
          <el-menu-item index="/product">产品中心</el-menu-item>
          <el-menu-item index="/goin">企业发展</el-menu-item>
        </el-menu>
      </el-header>
      <el-main>
        <router-view />
      </el-main>
      <div class="footer" v-show="isShow">
        <div class="footer-content">
          <ul class="content-nav">
            <li>
              <p>企业发展</p>
              <span>发展历程</span>
              <span>企业文化</span>
            </li>
            <li>
              <p>新闻资讯</p>
              <span>公司新闻</span>
              <span>行业动态</span>
            </li>
            <li>
              <p>产品中心</p>
              <span>功能模块</span>
            </li>
            <li>
              <p>联系我们</p>
              <span>邮箱：xiaoyulaile+sanganshuiservice@gmail.com</span>
              <span>电话：14782580307</span>
              <span>地址：上海市静安区沪太路1111弄5号901-8室</span>
            </li>
          </ul>
        </div>
        <div class="copyright">
          <span>上海三竿水科技有限公司版权所有</span>
          <a href="http://beian.miit.gov.cn" target="_blank" style="color: #fff;">沪ICP备2022022675号-2</a>
        </div>
      </div>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      router: true,
      defaultActive: "/",
      isShow: false
    };
  },
  methods: {
    handleSelect(key) {
      if (key.indexOf('/') !== -1) {
        this.isShow = this.defaultActive != key;
      } else {
        window.location.href = 'https://mall.yingqiuchang.com'
      }
    }
  }
};
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
}
html,
body {
  height: 100%;
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.el-header {
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 1240px;
  margin: 0 auto;
  //background-color: #fff;

  .logo {
    padding: 10px;
    display: flex;
    .t1 {
      font-size: 16px;
      font-weight: bold;
      color: #333;
    }
    .t2 {
      font-size: 10px;
      color: #666;
    }
    img {
      height: 40px;
      width: 40px;
      margin-right: 10px;
    }
  }
}
.el-main {
  padding: 0 !important;
}

.footer {
  width: 100%;
  height: 216px;
  overflow: hidden;
  background-color: #14679f;
  &-content {
    width: 1240px;
    margin: 0 auto;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    .content-nav {
      display: flex;
      justify-content: space-around;
      li {
        display: flex;
        flex-direction: column;
        padding: 0 20px;
        //justify-content: center;
        align-items: flex-start;
        p {
          font-size: 20px;
          color: #d4edff;
          padding: 10px 0;
        }
        span {
          color: #f7f7f7;
          font-weight: 300;
          padding: 5px 0;
        }
      }
    }
    img {
      width: 170px;
      height: 170px;
      padding: 10px;
    }
  }
  .copyright {
    height: 30px;
    background: #125688;
    text-align: center;
    span {
      color: #fff;
      line-height: 30px;
    }
  }
}
</style>
